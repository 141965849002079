html {
  box-sizing: border-box;
  font-size: 15px;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.87);
}

strong {
  font-weight: 700;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
}

p {
  line-height: 1.5em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

.wp-block-columns {
  display: flex;
  margin: 20px 0;
}

.wp-block-column {
  flex: 1;
  margin: 0 10px;
}

.wp-block-column:first-child {
  margin-left: 0;
}

.wp-block-column:last-child {
  margin-right: 0;
}

@media (max-width: 767px) {
  .wp-block-columns {
    display: block;
  }

  .wp-block-column {
    margin: 20px 0;
  }
}

.wp-block-image {
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),
              0 3px 1px -2px rgba(0,0,0,.12),
              0 1px 5px 0 rgba(0,0,0,.2);
  display: block;
  margin: 0;
  border-radius: 2px;
  max-width: 730px;
  text-align: center;
}

.wp-block-image img {
  height: 286px;
  width: 100%;
  object-fit: contain;
  margin: 20px 0;
  max-width: 500px;
}

.wp-block-image figcaption {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  margin-top: 8px;
  font-size: 18px;
  font-weight: 700;
  padding: 20px;
  border-top: 1px solid #ddd;
}

.wp-block-image figcaption a {
  color: rgba(0, 0, 0, 0.87);
}

.wp-block-image figcaption a:hover {
  color: rgba(0, 0, 0, 0.67);
}

.wp-block-table {
  border-collapse: collapse;
  width: 100%;
  max-width: 520px;
}

.wp-block-table tr:nth-child(odd) {
  background: rgba(221, 221, 221, 0.5);
}

.wp-block-table td {
  padding: 8px 20px 8px 10px;
}

.wp-block-table td:last-child {
  padding-right: 10px;
}

.wp-block-table td:first-child {
  width: 57%;
}
